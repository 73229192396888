import React from 'react';
import { DollarSign } from 'react-feather';
import './billing.css';

const CreditBalance = ({ creditBalance }) => {
  return (
    <div className="credit-balance-card">
      <div className="card-header">
        <h3>
          <DollarSign size={18} style={{ marginRight: '8px' }} />
          Credit Balance
        </h3>
      </div>
      <div className="balance-display">
        <div className="balance-amount">
          ${creditBalance.toFixed(2)}
        </div>
        <div className="balance-label">
          Available Credits
        </div>
      </div>
      <div className="balance-info">
        <p>Credits are used for AI assistant interactions</p>
      </div>
    </div>
  );
};

export default CreditBalance;
