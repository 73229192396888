import { Settings } from 'react-feather';
import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../config/api';
import { formatPhoneNumber } from '../../utils/formatters';

const AssistantSection = ({ userId }) => {
  const [loading, setLoading] = useState(true);
  const [saveMessage, setSaveMessage] = useState('');
  const [callTransferEnabled, setCallTransferEnabled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [assistant, setAssistant] = useState({
    id: '',
    name: '',
    initialGreeting: '',
    prompt: '',
    callTransferNumber: '+1',
    notificationNumber: '+1',
    voice: 'female', // Set default to female
    notificationEmails: [], // Changed from notificationEmail to array
    vapi_assistant_id: ''
  });

  useEffect(() => {
    const fetchAssistant = async () => {
      if (!userId) {
        setLoading(false);
        return;
      }

      try {
        const token = localStorage.getItem('access_token');
        const response = await fetch(`${API_BASE_URL}/api/assistants`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          if (Array.isArray(data) && data.length > 0) {
            const assistantData = data[0];
            const callForwardNumber = assistantData.call_forward_number || '+1';
            setAssistant({
              id: assistantData.id?.toString() || '',
              name: assistantData.name || '',
              initialGreeting: assistantData.initial_greeting || '',
              prompt: assistantData.prompt || '',
              callTransferNumber: callForwardNumber,
              notificationNumber: assistantData.notification_number || '+1',
              voice: assistantData.voice || 'female', // Default to female if no voice is set
              notificationEmails: assistantData.notification_emails || [], // Handle array of emails
              vapi_assistant_id: assistantData.vapi_assistant_id || ''
            });
            // Only enable if there's a valid number (more than just '+1')
            setCallTransferEnabled(!!callForwardNumber && callForwardNumber !== '+1');
          }
        }
      } catch (error) {
        console.error('Error fetching assistant:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAssistant();
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === 'callTransferNumber' || name === 'notificationNumber') {
      newValue = formatPhoneNumber(value);
    }

    setAssistant((prevAssistant) => ({
      ...prevAssistant,
      [name]: newValue
    }));
    
    // Clear any existing save message when user makes changes
    setSaveMessage('');
  };

  const handleToggleCallTransfer = () => {
    setCallTransferEnabled(!callTransferEnabled);
    if (!callTransferEnabled) {
      setAssistant(prev => ({
        ...prev,
        callTransferNumber: prev.callTransferNumber || '+1'
      }));
    }
  };

  const handleAddEmail = (e) => {
    e.preventDefault();
    if (newEmail && !assistant.notificationEmails.includes(newEmail)) {
      setAssistant(prev => ({
        ...prev,
        notificationEmails: [...prev.notificationEmails, newEmail]
      }));
      setNewEmail('');
      setSaveMessage('');
    }
  };

  const handleRemoveEmail = (emailToRemove) => {
    setAssistant(prev => ({
      ...prev,
      notificationEmails: prev.notificationEmails.filter(email => email !== emailToRemove)
    }));
    setSaveMessage('');
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    if (!userId || !assistant.id) return;

    // Show confirmation dialog
    const confirmMessage = "Are you sure you want to save these changes? This will update your AI assistant's instructions and behavior.";
    if (!window.confirm(confirmMessage)) {
      return;
    }

    try {
      const token = localStorage.getItem('access_token');
      const response = await fetch(`${API_BASE_URL}/api/assistants/${assistant.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          name: assistant.name,
          initial_greeting: assistant.initialGreeting,
          prompt: assistant.prompt,
          call_forward_number: callTransferEnabled ? assistant.callTransferNumber : null,
          notification_number: notificationsEnabled ? assistant.notificationNumber : null,
          voice: assistant.voice,
          notification_emails: assistant.notificationEmails,
          vapi_assistant_id: assistant.vapi_assistant_id
        }),
      });

      if (response.ok) {
        setSaveMessage('Assistant settings saved successfully. Your AI assistant has been updated with the new instructions.');
      } else {
        setSaveMessage('Failed to save changes. Please try again.');
      }
    } catch (error) {
      console.error('Error updating assistant:', error);
      setSaveMessage('An error occurred while saving changes. Please try again.');
    }
  };

  if (loading) {
    return (
      <section className="account-section">
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          Loading assistant settings...
        </div>
      </section>
    );
  }

  return (
    <section className="account-section">
      <h2 className="section-header">
        <Settings style={{ color: 'white', width: '1.2rem', height: '1.2rem' }} />
        Assistant Settings
      </h2>
      {saveMessage && (
        <div className={`save-message ${saveMessage.includes('successfully') ? 'success' : 'error'}`}>
          {saveMessage}
        </div>
      )}
      <form onSubmit={handleSaveChanges}>
        <div className="grid-container">
          <div className="input-group">
            <label>Assistant Name</label>
            <input
              type="text"
              name="name"
              value={assistant.name}
              onChange={handleInputChange}
              placeholder="Assistant Name"
            />
          </div>
          <div className="input-group">
            <label>Initial Greeting</label>
            <input
              type="text"
              name="initialGreeting"
              value={assistant.initialGreeting}
              onChange={handleInputChange}
              placeholder="Initial Greeting"
            />
          </div>
          <div className="input-group">
            <div className="toggle-container">
              <label>Call Transfer Number</label>
              <button
                type="button"
                onClick={handleToggleCallTransfer}
                className={`toggle-button ${callTransferEnabled ? 'active' : ''}`}
              >
                <div className="toggle-slider" />
              </button>
            </div>
            {callTransferEnabled && (
              <input
                type="tel"
                name="callTransferNumber"
                value={assistant.callTransferNumber}
                onChange={handleInputChange}
                placeholder="+1 (555) 555-5555"
              />
            )}
          </div>
          <div className="input-group">
            <div className="toggle-container">
              <label>Notification Number</label>
              <button
                type="button"
                disabled={true}
                className="toggle-button"
              >
                <div className="toggle-slider" />
              </button>
            </div>
          </div>
          <div className="input-group">
            <label>Assistant Voice</label>
            <select
              name="voice"
              value={assistant.voice}
              onChange={handleInputChange}
              className="voice-select"
            >
              <option value="female">Female</option>
              <option value="male">Male</option>
            </select>
          </div>
          <div className="input-group notification-emails">
            <label>Notification Emails</label>
            <div className="email-list">
              {assistant.notificationEmails.map((email, index) => (
                <div key={index} className="email-item">
                  <span>{email}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveEmail(email)}
                    className="remove-email"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
            <div className="add-email-container">
              <input
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="Add new email"
                className="add-email-input"
              />
              <button
                type="button"
                onClick={handleAddEmail}
                className="add-email-button"
              >
                Add
              </button>
            </div>
          </div>
        </div>
        <div className="input-group">
          <label>Your Assistant's Prompt</label>
          <textarea
            name="prompt"
            value={assistant.prompt}
            onChange={handleInputChange}
            placeholder="About Your Business"
            className="prompt-textarea"
          />
        </div>
        <button type="submit" className="primary-button">Save Assistant Changes</button>
      </form>
      <style jsx>{`
        .notification-emails {
          margin-bottom: 1rem;
        }
        .email-list {
          margin-bottom: 0.5rem;
        }
        .email-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: rgba(255, 255, 255, 0.1);
          padding: 0.5rem;
          margin-bottom: 0.5rem;
          border-radius: 4px;
          color: white;
        }
        .remove-email {
          background: none;
          border: none;
          color: #dc3545;
          font-size: 1.2rem;
          cursor: pointer;
          padding: 0 0.5rem;
        }
        .add-email-container {
          display: flex;
          gap: 0.5rem;
        }
        .add-email-input {
          flex: 1;
          padding: 0.5rem;
          border: 1px solid #ced4da;
          border-radius: 4px;
          background: rgba(255, 255, 255, 0.1);
          color: white;
        }
        .add-email-input::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }
        .add-email-button {
          padding: 0.5rem 1rem;
          background: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }
        .add-email-button:hover {
          background: #0056b3;
        }
      `}</style>
    </section>
  );
};

export default AssistantSection;
