import { FileText, Trash2, Eye, Save } from 'react-feather';
import { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../config/api';

const DocumentsSection = ({ userId }) => {
  const [loading, setLoading] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('');
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentContent, setDocumentContent] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [error, setError] = useState(null);

  const getFileType = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    return extension;
  };

  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem('access_token');
      console.log('Fetching documents with token:', token ? 'Token exists' : 'No token');
      
      if (!token) {
        throw new Error('No access token found');
      }

      console.log('Making request to:', `${API_BASE_URL}/api/documents`);
      const response = await fetch(`${API_BASE_URL}/api/documents`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });
      
      console.log('Documents API Response Status:', response.status);
      
      if (response.ok) {
        const data = await response.json();
        console.log('Raw documents response:', data);
        
        if (Array.isArray(data)) {
          console.log('Number of documents received:', data.length);
          console.log('First document (if exists):', data[0]);
          setDocuments(data);
        } else {
          console.error('Received non-array data:', data);
          setDocuments([]);
        }
      } else {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(`Failed to fetch documents: ${errorText}`);
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
      setError(error.message);
      setDocuments([]); // Ensure documents is always an array
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      console.log('Initializing documents fetch for user:', userId);
      fetchDocuments();
    } else {
      console.log('No userId provided');
      setLoading(false);
    }
  }, [userId]);

  const viewDocument = async (documentId, documentName) => {
    try {
      setError(null);
      const fileType = getFileType(documentName);
      setSelectedDocumentType(fileType);

      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error('No access token found');
      }

      console.log('Viewing document:', documentId);
      console.log('Making request to:', `${API_BASE_URL}/api/documents/${documentId}`);
      
      const response = await fetch(`${API_BASE_URL}/api/documents/${documentId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });

      console.log('View document response status:', response.status);

      if (response.ok) {
        if (fileType === 'txt') {
          const data = await response.json();
          console.log('Document content received:', data);
          
          if (data && data.content !== undefined) {
            setDocumentContent(data.content);
            setSelectedDocument(documentId);
            setIsEditing(false);
            setSaveStatus('');
          } else {
            throw new Error('Invalid document data received');
          }
        } else {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          window.open(url, '_blank');
          window.URL.revokeObjectURL(url);
          setSelectedDocument(null);
          setDocumentContent('');
          setIsEditing(false);
        }
      } else {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(`Failed to fetch document: ${errorText}`);
      }
    } catch (error) {
      console.error('Error fetching document content:', error);
      setError(error.message);
      setSelectedDocument(null);
      setDocumentContent('');
      setIsEditing(false);
      setSelectedDocumentType(null);
    }
  };

  const saveDocument = async () => {
    if (!selectedDocument || selectedDocumentType !== 'txt') return;

    try {
      setError(null);
      setSaveStatus('Saving...');
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error('No access token found');
      }

      console.log('Saving document:', selectedDocument);
      console.log('Making request to:', `${API_BASE_URL}/api/documents/${selectedDocument}`);

      const response = await fetch(`${API_BASE_URL}/api/documents/${selectedDocument}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({ content: documentContent })
      });

      console.log('Save document response status:', response.status);

      if (response.ok) {
        setSaveStatus('Saved successfully!');
        setTimeout(() => {
          setSelectedDocument(null);
          setDocumentContent('');
          setIsEditing(false);
          setSaveStatus('');
          setSelectedDocumentType(null);
        }, 1000);
      } else {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(`Failed to save document: ${errorText}`);
      }
    } catch (error) {
      console.error('Error saving document:', error);
      setSaveStatus('Error saving document');
      setError(error.message);
    }
  };

  const deleteDocument = async (documentId) => {
    try {
      setError(null);
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error('No access token found');
      }

      console.log('Deleting document:', documentId);
      console.log('Making request to:', `${API_BASE_URL}/api/documents/${documentId}`);

      const response = await fetch(`${API_BASE_URL}/api/documents/${documentId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      console.log('Delete document response status:', response.status);

      if (response.ok) {
        await fetchDocuments();
        if (selectedDocument === documentId) {
          setSelectedDocument(null);
          setDocumentContent('');
          setIsEditing(false);
          setSaveStatus('');
          setSelectedDocumentType(null);
        }
      } else {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(`Failed to delete document: ${errorText}`);
      }
    } catch (error) {
      console.error('Error deleting document:', error);
      setError(error.message);
    }
  };

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
    setUploadStatus('');
    setError(null);
  };

  const handleUpload = async () => {
    if (!selectedFiles.length) {
      setUploadStatus('Please select files to upload');
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('files', selectedFiles[i]);
    }

    try {
      setError(null);
      setUploadStatus('Uploading...');
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error('No access token found');
      }

      console.log('Uploading files...');
      console.log('Making request to:', `${API_BASE_URL}/api/upload-documents`);

      const response = await fetch(`${API_BASE_URL}/api/upload-documents`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData,
      });

      console.log('Upload response status:', response.status);

      if (response.ok) {
        const data = await response.json();
        console.log('Upload response:', data);
        setUploadStatus('Files uploaded successfully');
        setSelectedFiles([]);
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) {
          fileInput.value = '';
        }
        await fetchDocuments();
      } else {
        const errorData = await response.text();
        console.error('Upload error response:', errorData);
        setUploadStatus(`Upload failed: ${errorData}`);
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      setUploadStatus('Error uploading files. Please try again.');
      setError(error.message);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  if (loading) {
    return (
      <section className="account-section">
        <div style={{ textAlign: 'center', padding: '2rem' }}>
          Loading documents...
        </div>
      </section>
    );
  }

  return (
    <section className="account-section">
      <h2 className="section-header">
        <FileText style={{ color: 'white', width: '1.2rem', height: '1.2rem' }} />
        Knowledge Documents
      </h2>
      <div className="documents-container">
        <p className="section-description">Access and manage your knowledge documents here</p>
        
        {error && (
          <div className="error-message" style={{
            background: 'rgba(220, 53, 69, 0.1)',
            color: '#dc3545',
            padding: '0.75rem',
            borderRadius: 'var(--border-radius-md)',
            marginBottom: '1rem'
          }}>
            {error}
          </div>
        )}

        <div className="upload-container">
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="file-input"
          />
          {uploadStatus && (
            <p className={`upload-status ${uploadStatus.includes('success') ? 'success' : 'error'}`}>
              {uploadStatus}
            </p>
          )}
          <button
            onClick={handleUpload}
            className="upload-button"
          >
            Upload Documents
          </button>
        </div>

        <div className="document-list">
          {documents.length === 0 ? (
            <p className="no-documents">No documents uploaded yet.</p>
          ) : (
            documents.map((doc) => (
              <div key={doc.id} className="document-item">
                <div className="document-info">
                  <span className="document-name">{doc.document_name}</span>
                  <span className="document-date">{formatDate(doc.upload_date)}</span>
                </div>
                <div className="document-actions">
                  <button
                    onClick={() => viewDocument(doc.id, doc.document_name)}
                    className="icon-button"
                    title="View Document"
                  >
                    <Eye size={16} />
                  </button>
                  <button
                    onClick={() => deleteDocument(doc.id)}
                    className="icon-button"
                    title="Delete Document"
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </div>
            ))
          )}
        </div>

        {selectedDocument && selectedDocumentType === 'txt' && (
          <div className="document-content-container">
            <div className="document-actions-bar">
              <button
                onClick={() => setIsEditing(!isEditing)}
                className="edit-button"
              >
                {isEditing ? 'Cancel Edit' : 'Edit'}
              </button>
              {isEditing && (
                <button
                  onClick={saveDocument}
                  className="save-button"
                  title="Save Document"
                >
                  <Save size={16} /> Save
                </button>
              )}
              {saveStatus && (
                <span className={`save-status ${saveStatus.includes('Error') ? 'error' : 'success'}`}>
                  {saveStatus}
                </span>
              )}
            </div>
            {isEditing ? (
              <textarea
                className="document-editor"
                value={documentContent}
                onChange={(e) => setDocumentContent(e.target.value)}
              />
            ) : (
              <div className="document-content">
                {documentContent}
              </div>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default DocumentsSection;
