import { API_BASE_URL } from '../../config/api';

export const formatDate = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const fetchCreditBalance = async (userId, token) => {
  const response = await fetch(`${API_BASE_URL}/api/stripe/credit-balance/${userId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return await response.json();
};

export const fetchUsageHistory = async (userId, token) => {
  const response = await fetch(`${API_BASE_URL}/api/stripe/usage/${userId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return await response.json();
};

export const fetchSubscriptionDetails = async (userId, token) => {
  const response = await fetch(`${API_BASE_URL}/api/stripe/subscription/${userId}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return await response.json();
};
