import { useState, useEffect, useCallback } from 'react';
import { Package, Clock, Activity } from 'react-feather';
import PageContainer from '../components/PageContainer';
import CreditBalance from './billing/CreditBalance';
import PaymentMethods from './billing/PaymentMethods';
import TransactionHistory from './billing/TransactionHistory';
import UsageHistory from './billing/UsageHistory';
import {
  fetchCreditBalance,
  fetchUsageHistory,
  fetchSubscriptionDetails
} from './billing/BillingUtils';
import './billing/billing.css';

const Billing = () => {
  const [creditBalance, setCreditBalance] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [error, setError] = useState(null);
  const [usageData, setUsageData] = useState([]);
  const [subscription, setSubscription] = useState(null);

  const loadCreditBalance = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');
      const data = await fetchCreditBalance(userId, token);
      if (data.credit_balance !== undefined) {
        setCreditBalance(data.credit_balance);
      }
    } catch (err) {
      console.error('Error loading credit balance:', err);
    }
  };

  const loadUsageHistory = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');
      const data = await fetchUsageHistory(userId, token);
      if (data.usage) {
        setUsageData(data.usage);
      }
    } catch (err) {
      console.error('Error loading usage history:', err);
    }
  };

  const loadSubscriptionDetails = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('access_token');
      const data = await fetchSubscriptionDetails(userId, token);
      if (data) {
        setSubscription(data.subscription);
      }
    } catch (err) {
      console.error('Error loading subscription details:', err);
    }
  };

  const loadInitialData = useCallback(async () => {
    try {
      setPageLoading(true);
      await Promise.all([
        loadCreditBalance(),
        loadSubscriptionDetails(),
        loadUsageHistory()
      ]);
    } catch (err) {
      setError('Failed to load billing information');
      console.error('Error loading billing information:', err);
    } finally {
      setPageLoading(false);
    }
  }, []);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  const handleError = (errorMessage) => {
    setError(errorMessage);
  };

  return (
    <PageContainer
      loading={pageLoading}
      loadingMessage="Loading billing information..."
      title="Billing & Subscription"
      subtitle="Manage your subscription and billing information"
    >
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      {/* Credit Balance and Payment Methods Section */}
      <div className="billing-layout">
        <CreditBalance
          creditBalance={creditBalance}
          onError={handleError}
        />
        <PaymentMethods />
      </div>

      {/* Current Plan Section */}
      <h2 className="section-title">
        <Package className="section-icon" />
        Current Plan
      </h2>

      <div className="subscription-card">
        <div className="subscription-status">
          <h3>Subscription Status</h3>
          <p>{subscription?.status || 'Trial'}</p>
          {subscription?.trial_end && (
            <p>Trial ends: {new Date(subscription.trial_end * 1000).toLocaleDateString()}</p>
          )}
        </div>
      </div>

      {/* Transaction History Section */}
      <h2 className="section-title">
        <Clock className="section-icon" />
        Transaction History
      </h2>

      <TransactionHistory />

      {/* Usage History Section */}
      <h2 className="section-title">
        <Activity className="section-icon" />
        Usage History
      </h2>

      <UsageHistory usageData={usageData} />
    </PageContainer>
  );
};

export default Billing;
