import React from 'react';
import { formatDate } from './BillingUtils';
import './billing.css';

const UsageHistory = ({ usageData = [] }) => {
  const formatDuration = (seconds) => {
    if (!seconds) return '0:00';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Desktop view component
  const DesktopView = () => (
    <div className="usage-table desktop-only">
      <div className="usage-header">
        <div className="usage-cell">Channel</div>
        <div className="usage-cell">Date</div>
        <div className="usage-cell">Time</div>
        <div className="usage-cell">Duration</div>
        <div className="usage-cell">Cost</div>
      </div>
      {usageData.map((entry) => (
        <div key={entry.id} className="usage-row">
          <div className="usage-cell">
            {entry.service_type === 'vapi_call' ? 'Phone call' : entry.service_type}
          </div>
          <div className="usage-cell">{formatDate(entry.timestamp).split(' at ')[0]}</div>
          <div className="usage-cell">{formatDate(entry.timestamp).split(' at ')[1]}</div>
          <div className="usage-cell">
            {formatDuration(entry.conversation?.duration)}
          </div>
          <div className="usage-cell cost">${entry.cost.toFixed(2)}</div>
        </div>
      ))}
    </div>
  );

  // Mobile view component
  const MobileView = () => (
    <div className="mobile-only">
      {usageData.map((entry) => (
        <div key={entry.id} className="usage-entry">
          <div className="usage-entry-header">
            <span className="usage-type">
              {entry.service_type === 'vapi_call' ? 'Phone call' : entry.service_type}
            </span>
            <span className="usage-cost">${entry.cost.toFixed(2)}</span>
          </div>
          <div className="usage-details">
            <p>{formatDate(entry.timestamp)}</p>
            <p>Duration: {formatDuration(entry.conversation?.duration)}</p>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="usage-history">
      {usageData.length === 0 ? (
        <p style={{ textAlign: 'center', color: '#888' }}>No usage history available</p>
      ) : (
        <>
          <DesktopView />
          <MobileView />
        </>
      )}
    </div>
  );
};

export default UsageHistory;
