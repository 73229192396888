import { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../UserContext';

const Signup = () => {
  const [businessName, setBusinessName] = useState('');
  const [businessUrl, setBusinessUrl] = useState('');
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [statusType, setStatusType] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [creatingAccount, setCreatingAccount] = useState(false);

  const navigate = useNavigate();
  const { login } = useContext(UserContext);
  const apiUrl = process.env.REACT_APP_API_URL;
  const isPopup = new URLSearchParams(window.location.search).get('popup') === 'true';

  const validateUrl = (url) => {
    // Remove any protocol prefix if present
    const cleanUrl = url.replace(/^(https?:\/\/)?(www\.)?/, '');
    // Extract the domain part (everything up to the first slash or end of string)
    const domain = cleanUrl.split('/')[0];
    // Check if the domain ends with a valid TLD
    const re = /\.(com|ca|net|org|edu|gov|io|co|ai)$/i;
    return re.test(domain);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateForm = () => {
    if (!validateUrl(businessUrl)) {
      setStatusMessage('Please enter a valid business URL');
      setStatusType('error');
      return false;
    }

    if (!validateEmail(email)) {
      setStatusMessage('Please enter a valid email address');
      setStatusType('error');
      return false;
    }

    if (businessName.trim() === '') {
      setStatusMessage('Please enter your business name');
      setStatusType('error');
      return false;
    }

    return true;
  };

  const updateStatus = (type, message, showLoadingSpinner = true) => {
    setStatusType(type);
    setStatusMessage(message);
    setShowSpinner(showLoadingSpinner);
  };

  const handleInitialSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setLoading(true);
    updateStatus('info', 'Checking website accessibility...');

    try {
      // First check if website is accessible
      const accessResponse = await fetch(`${apiUrl}/api/signup/check-website`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url: businessUrl
        })
      });

      if (!accessResponse.ok) {
        const data = await accessResponse.json();
        updateStatus('error', data.detail || 'Website is not accessible', false);
        setLoading(false);
        return;
      }

      updateStatus('info', 'Sending verification code to your email...');

      const response = await fetch(`${apiUrl}/api/signup/initiate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          business_url: businessUrl,
          email,
          business_name: businessName
        })
      });

      const data = await response.json();

      if (response.ok && data.success) {
        if (data.requires_verification) {
          setShowVerification(true);
          updateStatus('success', 'Please check your email for the verification code', false);
        } else {
          updateStatus('success', data.message, false);
          setBusinessName('');
          setBusinessUrl('');
          setEmail('');
        }
      } else {
        const errorMessage = typeof data.detail === 'object' ? JSON.stringify(data.detail) : data.detail || data.message || 'An error occurred. Please try again.';
        updateStatus('error', errorMessage, false);
      }
    } catch (error) {
      console.error('Error:', error);
      updateStatus('error', 'An unexpected error occurred. Please try again later.', false);
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    if (resendDisabled) return;
    
    setLoading(true);
    updateStatus('info', 'Resending verification code...');
    setResendDisabled(true);

    try {
      const response = await fetch(`${apiUrl}/api/signup/resend-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (response.ok && data.success) {
        updateStatus('success', 'New verification code sent to your email', false);
        // Enable resend button after 1 minute
        setTimeout(() => setResendDisabled(false), 60000);
      } else {
        const errorMessage = typeof data.detail === 'object' ? JSON.stringify(data.detail) : data.detail || data.message || 'Failed to resend code';
        updateStatus('error', errorMessage, false);
        setResendDisabled(false);
      }
    } catch (error) {
      console.error('Error:', error);
      updateStatus('error', 'An unexpected error occurred. Please try again later.', false);
      setResendDisabled(false);
    } finally {
      setLoading(false);
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    
    if (!verificationCode) {
      updateStatus('error', 'Please enter the verification code', false);
      return;
    }

    setLoading(true);
    setCreatingAccount(true);
    updateStatus('info', 'Verifying code...');

    try {
      // More detailed sequence of status messages with longer intervals
      setTimeout(() => updateStatus('info', 'Analyzing your website structure...'), 3000);
      setTimeout(() => updateStatus('info', 'Scanning website content...'), 15000);
      setTimeout(() => updateStatus('info', 'Extracting business information...'), 35000);
      setTimeout(() => updateStatus('info', 'Building AI knowledge base...'), 60000);
      setTimeout(() => updateStatus('info', 'Training AI assistant with your business data...'), 90000);
      setTimeout(() => updateStatus('info', 'Configuring phone system...'), 110000);
      setTimeout(() => updateStatus('info', 'Finalizing your workspace...'), 120000);

      const response = await fetch(`${apiUrl}/api/signup/verify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          code: verificationCode
        })
      });

      const data = await response.json();

      if (response.ok && data.success) {
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('userId', data.userId);
        login(data.userId, data.isAdmin);
        
        updateStatus('success', 'Account created successfully!', false);
        
        if (isPopup && window.opener) {
          // Notify parent window and let it handle navigation
          window.opener.postMessage(
            { type: 'SIGNUP_COMPLETE' },
            window.location.origin
          );
        } else {
          // Handle navigation directly
          updateStatus('success', 'Account created successfully! Redirecting to dashboard...', false);
          setTimeout(() => {
            navigate('/dashboard');
          }, 2000);
        }
      } else {
        const errorMessage = typeof data.detail === 'object' ? JSON.stringify(data.detail) : data.detail || data.message || 'An error occurred. Please try again.';
        updateStatus('error', errorMessage, false);
      }
    } catch (error) {
      console.error('Error:', error);
      updateStatus('error', 'An unexpected error occurred. Please try again later.', false);
    } finally {
      setLoading(false);
      setCreatingAccount(false);
    }
  };

  return (
    <div className="signup-container login-page" style={isPopup ? { maxWidth: '100%', margin: 0 } : undefined}>
      <div className="form-wrapper">
        {statusMessage && (
          <div 
            className={`status-message ${statusType}-message`}
            style={{
              background: 'rgba(0, 0, 0, 0.5)',
              border: '1px solid rgba(255, 255, 255, 0.2)',
              display: 'flex',
              alignItems: 'center',
              gap: '12px',
              padding: '12px 16px',
              borderRadius: '8px',
              marginBottom: '1rem'
            }}
          >
            {((loading || creatingAccount) && showSpinner) && (
              <div 
                className="loading-spinner"
                style={{
                  width: '20px',
                  height: '20px',
                  border: '2px solid #ffffff',
                  borderTop: '2px solid transparent',
                  borderRadius: '50%',
                  animation: 'spin 1s linear infinite',
                  flexShrink: 0
                }}
              />
            )}
            <p style={{
              margin: 0,
              color: 'white',
              fontWeight: 'bold',
              fontSize: '0.95rem',
              lineHeight: 1.4,
              flex: 1,
              textShadow: '0 1px 2px rgba(0, 0, 0, 0.2)'
            }}>
              {statusMessage}
            </p>
          </div>
        )}

        {!showVerification ? (
          <>
            <div className="form-logo-wrapper">
              <img src="/images/3.png" alt="SalesOptAI" className="form-logo" style={{ height: 'auto', maxHeight: '100px' }} />
            </div>
            <form onSubmit={handleInitialSubmit} className="assistant-form" style={{ maxWidth: '500px', width: '100%' }}>
              <input
                type="text"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                placeholder="Business Name"
                required
                disabled={loading}
                style={{ maxWidth: '500px', width: '100%' }}
              />
              
              <input
                type="text"
                value={businessUrl}
                onChange={(e) => setBusinessUrl(e.target.value)}
                placeholder="Business URL"
                required
                disabled={loading}
                style={{ maxWidth: '500px', width: '100%' }}
              />

              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
                required
                disabled={loading}
                style={{ maxWidth: '500px', width: '100%' }}
              />
              
              <button type="submit" className="btn" disabled={loading} style={{ maxWidth: '500px', width: '100%' }}>
                {loading ? (
                  <>
                    <span className="loading-spinner"></span>
                    Processing...
                  </>
                ) : (
                  'Create Account'
                )}
              </button>
            </form>
            <div style={{ textAlign: 'center', marginTop: '1rem' }}>
              <p>
                Already have an account? <Link to="/login" style={{ color: 'var(--accent)' }}>Log In</Link>
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="form-logo-wrapper">
              <img src="/images/3.png" alt="SalesOptAI" className="form-logo" style={{ height: 'auto', maxHeight: '100px' }} />
            </div>
            <form onSubmit={handleVerificationSubmit} className="assistant-form" style={{ maxWidth: '500px', width: '100%' }}>
              <p className="verification-message" style={{ color: 'white', textAlign: 'center', marginBottom: '1.5rem' }}>
                Please enter the verification code sent to your email
              </p>
              <div style={{ position: 'relative', width: '100%', marginBottom: '1rem' }}>
                <input
                  type="text"
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  placeholder="Verification Code"
                  required
                  disabled={loading}
                  maxLength={6}
                  pattern="\d{6}"
                  style={{ paddingRight: '100px', maxWidth: '500px', width: '100%' }}
                />
                <button
                  type="button"
                  onClick={handleResendCode}
                  disabled={loading || resendDisabled}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    color: 'var(--accent)',
                    cursor: resendDisabled ? 'not-allowed' : 'pointer',
                    opacity: resendDisabled ? 0.5 : 1,
                    padding: '5px',
                    fontSize: '0.9em'
                  }}
                >
                  Resend Code
                </button>
              </div>
              
              <button type="submit" className="btn" disabled={loading} style={{ maxWidth: '500px', width: '100%' }}>
                {loading ? (
                  <>
                    <span className="loading-spinner"></span>
                    Creating Account...
                  </>
                ) : (
                  'Create Account'
                )}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default Signup;
