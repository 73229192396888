import React from 'react';
import { CreditCard } from 'react-feather';
import './billing.css';

const PaymentMethods = () => {
  return (
    <div className="payment-methods-card">
      <div className="card-header">
        <h3>
          <CreditCard size={18} style={{ marginRight: '8px' }} />
          Payment Methods
        </h3>
      </div>
      
      <div className="payment-methods-content">
        <p className="placeholder-message">
          Payment methods will be available soon. You can continue using the service during the trial period.
        </p>
      </div>
    </div>
  );
};

export default PaymentMethods;
