import React from 'react';
import { Clock } from 'react-feather';
import './billing.css';

const TransactionHistory = () => {
  return (
    <div className="transaction-history-card">
      <div className="card-header">
        <h3>
          <Clock size={18} style={{ marginRight: '8px' }} />
          Transaction History
        </h3>
      </div>
      
      <div className="transaction-history-content">
        <p className="placeholder-message">
          Transaction history will be available once payment processing is enabled. For now, you can track your usage in the Usage History section.
        </p>
      </div>
    </div>
  );
};

export default TransactionHistory;
