import { API_BASE_URL } from '../../config/api';

export const fetchDashboardData = async (accessToken, userId) => {
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json'
  };

  try {
    const [profileResponse, assistantsResponse, usageResponse, creditsResponse, analysisResponse] = await Promise.all([
      fetch(`${API_BASE_URL}/api/user-profile`, { headers }),
      fetch(`${API_BASE_URL}/api/assistants`, { headers }),
      fetch(`${API_BASE_URL}/api/usage`, { headers }),
      fetch(`${API_BASE_URL}/api/credits/balance`, { headers }),
      fetch(`${API_BASE_URL}/api/analysis`, { headers })
    ]);

    const [profileData, assistants, usageData, creditsData, analysisData] = await Promise.all([
      profileResponse.json(),
      assistantsResponse.json(),
      usageResponse.json(),
      creditsResponse.json(),
      analysisResponse.json()
    ]);

    return {
      business_name: profileData.business_name || 'Business Name Not Found',
      phone_number: assistants[0]?.phone_number || 'Phone Number Not Assigned',
      credit_balance: creditsData.balance || 0,
      subscription_status: profileData.subscription_status || 'trial',
      trial_end_date: profileData.trial_end || null,
      recent_usage: usageData.recent_usage || [],
      usage_stats: usageData.usage_stats || {
        total_calls: 0,
        total_chats: 0,
        total_duration: 0,
        total_customers: 0
      },
      analysis: analysisData || {
        daily: {
          total_interactions: 0,
          avg_duration: 0,
          sentiment_score: 0,
          ai_leads: 0,
          ai_closed_deals: 0,
          ai_prospects: 0
        },
        weekly: {
          total_interactions: 0,
          avg_duration: 0,
          sentiment_score: 0,
          ai_leads: 0,
          ai_closed_deals: 0,
          ai_prospects: 0
        },
        monthly: {
          total_interactions: 0,
          avg_duration: 0,
          sentiment_score: 0,
          ai_leads: 0,
          ai_closed_deals: 0,
          ai_prospects: 0
        }
      }
    };
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    throw error;
  }
};

export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const formatDuration = (duration) => {
  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;
  return `${minutes}m ${seconds}s`;
};

export const getSentimentEmoji = (score) => {
  if (score >= 0.8) return '😊';
  if (score >= 0.6) return '🙂';
  return '😐';
};
