import React from 'react';
import { DollarSign, Users, Clock, Activity } from 'react-feather';

const StatCard = ({ icon: Icon, title, value, subtitle, color }) => (
  <div className="stat-card">
    <div className="stat-icon" style={{ backgroundColor: `rgba(${color}, 0.1)` }}>
      <Icon size={20} style={{ color: `rgb(${color})` }} />
    </div>
    <div className="stat-content">
      <div className="stat-title">{title}</div>
      <div className="stat-value" style={{ color: `rgb(${color})` }}>{value}</div>
      {subtitle && <div className="stat-subtitle">{subtitle}</div>}
    </div>
  </div>
);

const getSubscriptionStatusColor = (status) => {
  switch (status) {
    case 'trial':
      return '255, 159, 64'; // Orange
    case 'active':
      return '34, 205, 148'; // Green
    case 'suspended':
    case 'canceled':
      return '255, 99, 132'; // Red
    default:
      return '156, 156, 156'; // Grey
  }
};

const getSubscriptionStatusLabel = (status) => {
  switch (status) {
    case 'trial':
      return 'Free Trial';
    case 'active':
      return 'Active';
    case 'suspended':
    case 'canceled':
      return 'Suspended';
    default:
      return status.charAt(0).toUpperCase() + status.slice(1);
  }
};

const StatsGrid = ({ userData }) => {
  const subscriptionStatus = userData.subscription_status || 'trial';
  const creditBalance = userData.credit_balance || 0;
  const totalCustomers = userData.usage_stats?.total_customers || 0;
  const totalDuration = userData.usage_stats?.total_duration || 0;
  const totalInteractions = userData.usage_stats?.total_calls + userData.usage_stats?.total_chats || 0;

  const subscriptionColor = getSubscriptionStatusColor(subscriptionStatus);
  const subscriptionLabel = getSubscriptionStatusLabel(subscriptionStatus);

  const trialEndDate = userData.trial_end_date 
    ? new Date(userData.trial_end_date * 1000).toLocaleDateString()
    : null;

  return (
    <div className="stats-grid">
      <StatCard
        icon={Activity}
        title="Subscription Status"
        value={subscriptionLabel}
        color={subscriptionColor}
        subtitle={subscriptionStatus === 'trial' && trialEndDate ? `Trial ends: ${trialEndDate}` : null}
      />
      <StatCard
        icon={DollarSign}
        title="Credit Balance"
        value={`$${creditBalance.toFixed(2)}`}
        color="34, 205, 148"
      />
      <StatCard
        icon={Users}
        title="Total Customers"
        value={totalCustomers}
        color="99, 102, 241"
      />
      <StatCard
        icon={Clock}
        title="Total Interactions"
        value={totalInteractions}
        subtitle={`${Math.round(totalDuration / 60)} minutes total`}
        color="236, 72, 153"
      />
    </div>
  );
};

export default StatsGrid;
